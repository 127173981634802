/* import heroIndustry from './assets/static/hero-solar-2.jpg'; */
import contactHero from './assets/static/contact2.jpg';
import heroEolic from './assets/static/hero-eolic.jpg';
import heroEnvironment from './assets/static/environment/rabbit.jpg';
import mainLogo from './assets/static/main_logo.jpg';
import heroSolar from './assets/static/solar/heroSolar.jpg';
import heroMap from './assets/static/ubication.jpg';

/* eolic */
import towerCelosia from './assets/static/eolic/torrecelosia2.jpg';
import towerAutosuport from './assets/static/eolic/torreautosoportada2.jpg';
import towerTubular from './assets/static/eolic/torretubular3.jpg';
import anemometro from './assets/static/eolic/anemometro.jpg';
import veleta from './assets/static/eolic/veletaon.jpg';
import sensorT from './assets/static/eolic/sensorT.png';
import sensorH from './assets/static/eolic/sensorH.jpg';
import sensorP from './assets/static/eolic/sensorP.jpg';
import pluviometro from './assets/static/eolic/pluviometro.jpg';
import baliza from './assets/static/eolic/baliza.jpg';
import sistemAntiChock from './assets/static/eolic/sistemacontradescargas.jpg';
import disuador from './assets/static/eolic/disuador1.jpg';
import cimentacion from './assets/static/eolic/cimentacion.jpg';


/* environment */

import nido from './assets/static/environment/nido.jpg';
import rabbit from './assets/static/environment/rabbit.jpg';
import gecko from './assets/static/environment/gecko.jpg';
import bat from './assets/static/environment/bat.jpg';
import monitoring from './assets/static/environment/monitoring.png';
import muestreo from './assets/static/environment/muestreo2.png';
import disuador2 from './assets/static/eolic/disuador2.jpg';

/* solar */

import mantenimiento from './assets/static/solar/mantenimiento.jpg';
import piranometer from './assets/static/solar/piranometro.jpg';
import albedometer from './assets/static/solar/albedometro.jpg';
import anemometer from './assets/static/solar/anemometro.jpg';
import veletaSolar from './assets/static/solar/veleta.png';
import sensorSolarTemp from './assets/static/solar/sensorTempPanel.jpg';




const images = {
    mainLogo,
    /* heroIndustry, */
    heroSolar,
    heroEolic,
    heroEnvironment,
    contactHero,
    heroMap,
    towerCelosia,
    towerAutosuport,
    towerTubular,
    anemometro,
    veleta,
    sensorT,
    sensorH,
    sensorP,
    pluviometro,
    baliza,
    disuador,
    cimentacion,
    sistemAntiChock,

    /* environment */

    nido,
    rabbit,
    gecko,
    monitoring,
    muestreo,
    disuador2,
    bat,

    /* solar */

    mantenimiento,
    piranometer,
    anemometer,
    albedometer,
    veletaSolar,
    sensorSolarTemp,
}

export default images;
