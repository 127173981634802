import React from "react";
import TitleSection from './TitleSection';
import Paragraph from './Paragraph';
import { useTranslation } from 'react-i18next';
import Map from './Map';

const ServiceMap = () => {
    const { t } = useTranslation();
    return(
        <>
            <TitleSection title={t('ubication')} />
            <Paragraph content={t('mapDescript')} />
            <Map/>
        </>
    );
}

export default ServiceMap;