import React from "react";

const NotFound = () => (
        <>
        <h1>
            <strong>404</strong>
        </h1>
        <h3>
            Page not found
        </h3>
        </>
    );

export default NotFound;