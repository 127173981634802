import React from 'react';
import '../assets/styles/components/RedSocial.scss';
import { FaTwitterSquare, FaFacebookSquare,FaInstagramSquare } from "react-icons/fa";
import Teams from '../assets/static/Microsoft_Teams-Logo.png';
import { useTranslation } from 'react-i18next';

const RedSocial = () => {
	const { t } = useTranslation();
  return (
    	<div>
        	<div className="social-container">
            	<h2>{t('redSocial')}</h2>
            	<a rel="noopener noreferrer" target="_black" href="https://www.facebook.com/Wind-and-sun-100633492001541/">
					<FaFacebookSquare/>
				</a>
            	<a rel="noopener noreferrer" target="_black" href="https://www.instagram.com/wind_and_sunnnn/">
					<FaInstagramSquare/>
				</a>
            	<a rel="noopener noreferrer" href="#">
					<FaTwitterSquare/>
				</a>
        	</div>
        	<div className="social-container-teams">
            	<h2>{t('teams')}</h2>
				<a rel="noopener noreferrer" target="_black" href="https://teams.microsoft.com/l/team/19%3af4c5afd153a74d559fe4257f3a5efe20%40thread.tacv2/conversations?groupId=71b56799-eb60-4c79-acb1-4b733ef18e01&tenantId=95c22580-5377-49c7-808c-24f4bd10ee1c">
					<img src={Teams} alt="Teams"/>
				</a>
    
        	</div>
      	</div>
    );
  }


export default RedSocial;