import React from "react";
import TitleSection from "./TitleSection";
import Paragraph from "./Paragraph";
import ServiceCard from './ServiceCard';
import CenterCardContainer from './CenterCardContainer';
import { FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <TitleSection title={t('contact')} />
            <Paragraph content={t('contactHomeDescript')} />
            <br/><br/>
            <CenterCardContainer >
                <ServiceCard  title={t('sendMail')} ico={<FaEnvelope/>} route="/contacto" />
                <ServiceCard  title={t('ubication')} ico={<FaMapMarkerAlt/>} route="/ubicacion" />
            </CenterCardContainer>

        </>
    )
}


export default Contact