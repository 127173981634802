import React from "react";
import Hero from "../components/Hero";
import Divisor from "../components/Divisor";
import InformationCard from "../components/InformationCard";
import TitleSection from "../components/TitleSection";
import Paragraph from "../components/Paragraph";
import "../assets/styles/App.scss";
import images from '../Images';
import { environment } from '../cards.json';
import Breadcrumbs from "../components/Breadcrumbs";
import { useTranslation } from 'react-i18next';

const Environment = () => {
    const { heroEnvironment } = images;
    const { t } = useTranslation();
    return (
        <>
            <Hero hero={heroEnvironment} button={false} >  
                <h1>{t('service')}</h1>
                <h1>{t('headerEnvironment')}</h1>
                <h3>{t('subTitleEnvironment')}</h3>
            </Hero>
            <Breadcrumbs />
            <Divisor />
            <TitleSection title={t('environmentTitle')} />
            <Paragraph content={t('environmentDescript1')} />
            <Paragraph content={t('environmentDescript2')} />
            <Divisor />
            <TitleSection title={t('scopes')} />
            <InformationCard cardData={environment} />
        </>
    )
}

export default Environment;