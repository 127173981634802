import React from 'react';

const ItalicParragraph = ({ content }) => {
    const style = {
        display:"block"
    }
    return (
        <i style={style} className="center-section description-module">
            { content }
        </i>
    )
}

export default ItalicParragraph;