import React from "react";
import '../assets/styles/components/Allies.scss';
import west from '../assets/static/allies/west_logo.png';
import thiesClima from '../assets/static/allies/thiesclima_logo.svg';
import nrg from '../assets/static/allies/nrg_logo.png';
import kintech from '../assets/static/allies/logo-kintech.png';
import hukseflux from '../assets/static/allies/hukseflux_logo.svg';
import kipp from '../assets/static/allies/kipp_logo.svg';
import bioinsightLogo from '../assets/static/allies/bioinsight_logo.png';
import campbellLogo from '../assets/static/allies/campbell_logo.png';
import vaisalaLogo from '../assets/static/allies/vaisala_logo.svg';
import ammonitLogo from '../assets/static/allies/ammonit_logo.gif';
import eolic from '../assets/static/eolic.png';
import TitleSection from './TitleSection';
import { useTranslation } from 'react-i18next';

export default function Allies() {

    const { t } = useTranslation();

    return (
        <div className="allies-container" style={{ backgroundImage: `url(${eolic})` }}>
            <section className="allies">
                <TitleSection title={t("BusinessPartners")} />
                <p>
                    {t("BusinessPartnersDescript")}
                </p>
                <div>
                    <a rel="noopener noreferrer" href="https://www.ammonit.com/es/" target="_black">
                        <figure>
                            <img src={ammonitLogo} alt="logo1"/>
                        </figure>
                        {/* <h3>Vaisala.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.campbellsci.es/data-loggers" target="_black">
                        <figure>
                            <img src={campbellLogo} alt="logo2"/>
                        </figure>
                        {/* <h3>campbellsci.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.vaisala.com" target="_black" >
                        <figure>
                            <img src={vaisalaLogo} alt="logo-vaisala"/>
                        </figure>
                       {/*  <h3>vaisala.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://west-inc.com/" target="_black" >
                        <figure>
                            <img src={west} alt="logo-west"/>
                        </figure>
                        {/* <h3>West inc.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.bioinsight.pt/" target="_black">
                        <figure>
                            <img src={bioinsightLogo} alt="logo-bioinsigh"/>
                        </figure>
                        {/* <h3>bioinsight.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.nrgsystems.com/" target="_black">
                        <figure>
                            <img src={nrg} alt="logo-NRG"/>
                        </figure>
                        {/* <h3>Thies Clima.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.kintech-engineering.com/" target="_black">
                        <figure>
                            <img src={kintech} alt="logo-kintech"/>
                        </figure>
                        {/* <h3>Thies Clima.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.hukseflux.com/" target="_black">
                        <figure>
                            <img src={hukseflux} alt="logo-hukseflux"/>
                        </figure>
                        {/* <h3>Thies Clima.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.kippzonen.com/" target="_black">
                        <figure>
                            <img src={kipp} alt="logo-hukseflux"/>
                        </figure>
                        {/* <h3>Thies Clima.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.windsensor.com/en" target="_black">
                        <figure>
                            <img src='https://www.windsensor.com/application/themes/windsensor/img/windesnsor-logo-2016.svg' alt="logo-hukseflux"/>
                        </figure>
                        {/* <h3>Thies Clima.</h3> */}
                    </a>
                    <a rel="noopener noreferrer" href="https://www.thiesclima.com/en/" target="_black">
                        <figure>
                            <img src={thiesClima} alt="logo-thiesClima"/>
                        </figure>
                        {/* <h3>Thies Clima.</h3> */}
                    </a>
                </div>
            </section>
        </div>
    )
}