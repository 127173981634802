import React from "react";
import GeneralCard from './GeneralCard';
import { useTranslation } from 'react-i18next';
import '../assets/styles/components/InformationCard.scss';
import images from '../Images';

const InformationCard = ( { cardData } ) => {
    const { t } = useTranslation();

    return (
        <div className="center-section-card max-width-xs">
            {  
                cardData?.map(({id,title,description,cover}) => (
                    <GeneralCard key={id} title={t(title)} imag={images[cover]} description={t(description)} />
                ))
            }
        </div>
    )
}

export default InformationCard;