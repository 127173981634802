import React, { useState, useRef, useEffect } from "react";
import * as emailjs from "emailjs-com";
import '../assets/styles/components/SendMail.scss';
import TitleSection from './TitleSection';
import Paragraph from './Paragraph';
import CenterContent from './CenterContent';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
const TO_EMAIL = process.env.REACT_APP_TO_EMAIL;

const SendMailForm = () => {
  const { t } = useTranslation();
  const initialState = {
    data: {
      name: '',
      email: '',
      company: '',
      content: '',
      subject: ''
    }
  }

  const [dataForm, setDataForm] = useState(initialState)

  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  
  const handleClick = (event) => {
    event.preventDefault();
    const { name, email, company, content, subject } = dataForm.data;
    const data = {
      name,
      to_email: TO_EMAIL,
      from_email: email,
      company,
      content,
      subject
    };

    emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID).then((response) => {
        console.log(response.status, response.text);
        toast.info("Correo Enviado !", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDataForm(initialState);
        console.log('termino de enviar el correo');
        console.log(dataForm);
      },
      function (err) {
        console.log(err);
      }
    );
  }
  const handleChange = (event) => {
    setDataForm({
      data: {
        ...dataForm.data,
        [event.target.name]: event.target.value
      }
    });
  }
    
  	return (
		<>
    		<TitleSection title={t('titleModuleEmail')} />
        <CenterContent>
          <p>{t('descriptModuleEmail')}</p>
          <h3>julio.alravez@windandsun.net</h3>
        </CenterContent>
          <form id="form-send" onSubmit={handleClick}>
            <div className="center-section-component" id="contact">
              <input ref={inputElement} 
                      type="text" 
                      name="name" 
                      required="required" 
                      placeholder={t('NameLastname')} 
                      value={dataForm.data.name} 
                      onChange={handleChange}>
                
              </input>
              <input type="email" 
                      name="email" 
                      required="required" 
                      placeholder={t('email')} 
                      value={dataForm.data.email} 
                      onChange={handleChange}>
                    
              </input>
              <input type="text" 
                      name="company"
                      required="required"
                      placeholder={t('company')}
                      value={dataForm.data.company}
                      onChange={handleChange}>

              </input>
              <input type="text" 
                      name="subject" 
                      required="required" 
                      placeholder={t('subject')} 
                      value={dataForm.data.subject} 
                      onChange={handleChange}>

              </input>
              <textarea type="text" 
                        name="content" 
                        required="required" 
                        placeholder={t('content')} 
                        value={dataForm.data.content} 
                        onChange={handleChange}>

              </textarea>
              <button type="submit" className="btn-primary-outline">
                Send mail
              </button>
            </div>
          </form>
        <ToastContainer />
    	</>
    );
  }

export default SendMailForm;