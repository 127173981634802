import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/components/Hero.scss';
import { useTranslation } from 'react-i18next';
import 'react-slideshow-image/dist/styles.css'
import { Fade  } from 'react-slideshow-image';


const Hero = ({ hero, home=false, children }) => {
  
	const { t } = useTranslation();

    return(
    	<>
    		{
				home ?
					<Fade  duration="8000" transitionDuration="1000" indicators={true}>
							<div style={{ backgroundImage: `url(${hero[0]})` }} className="hero-img">
								<div>
									<h1>{t('service')}</h1>
									<h1>{t('headerEnvironment')}</h1>
									<h3>{t('subTitleEnvironment')}</h3>
									<br/>
									<br/>
									<Link to="/servicio-ambiental">{t('KnowMore')}</Link>
								</div>
							</div>
					
							<div style={{ backgroundImage: `url(${hero[1]})` }} className="hero-img">
								<div>
									<h1>{t('service')}</h1>
									<h1>{t('titleHeroEolic')}</h1>
									<h3>{t('subTitleHeroEolic')}</h3>
									<br/>
									<br/>
									<Link to="/servicio-eolico">{t('KnowMore')}</Link>
								</div>
							</div>
						
							<div style={{ backgroundImage: `url(${hero[2]})` }} className="hero-img">
								<div>
									<h1>{t('service')}</h1>
									<h1>{t('titleHeroSolar')}</h1>
									<h3>{t('subTitleHeroSolar')}</h3>
									<br/>
									<br/>
									<Link to="/servicio-solar">{t('KnowMore')}</Link>
								</div>
							</div>
						
							{/* <div style={{ backgroundImage: `url(${listHero[3]})` }} className="hero-img">
								<div>
									<h1>Servicio</h1>
									<h1>Industrial</h1>
									<h3>Diagnostico y puesta en marcha</h3>
									<br/>
									<br/>
									<a href="#company-industrial">Conocer mas</a>
								</div>
							</div> */}
						
					</Fade >
				:
					<div style={{ backgroundImage: `url(${hero})` }} className="hero-img">
						<div>
							{ children }
						</div>
            		</div>
			}
		</>
  	)
}

export default Hero;
