import React, { useState } from 'react';
import "../assets/styles/components/Header.scss";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FaSolarPanel, FaIndustry } from 'react-icons/fa'
import { GiWindTurbine, GiLindenLeaf } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdMenu } from "react-icons/io";
import FlagSelect from "../components/FlagSelect";
import useWindowDimensions from './UseWindow.js';
import images from '../Images';


const Header = () => {
    const { t, i18n } = useTranslation();
    const { mainLogo } = images;
    const { width } = useWindowDimensions();

    const handleChangeLanguage = (language) => {
        i18n.changeLanguage(language);
    }
    const [isShownService, setIsShownService] = useState(false);// false => hide sub menu

    const handleMenu = (state) => {
        if (state)
        document.querySelector('.content-menu').classList.replace('disabled','active')
        else
        document.querySelector('.content-menu').classList.replace('active','disabled')

    }

    return (
    <header className="header">
        { width > 1023 &&
            <Link to="/" >
                <figure>
                    <img className="header-img"src={mainLogo} alt="logo" />
                </figure>
            </Link>
        }
    

        <div className="navigation-container">
            <div className="navigation-header">
                { width > 1023 ?
                    <ul className="navigation-list">
                        <li className="option-header">
                            <Link onClick={() => setIsShownService(false)} name="dashboard" to="/">
                                {t('home')}
                            </Link> 
                        </li>
                        <li
                            onMouseEnter={() => setIsShownService(true)}
                            onMouseLeave={() => setIsShownService(false)}
                            className="option-header tab-service"
                        >
                            {isShownService && (
                                <div className="tab-service-menu">
                                    <div>
                                        <div>
                                            <Link onClick={() => setIsShownService(false)} name="environment" to="/servicio-ambiental">
                                                <span>
                                                    <GiLindenLeaf/>
                                                </span>
                                                <br/>
                                                    { t('headerEnvironment') }
                                            </Link> 
                                        </div>
                                        {/* <div>
                                            <h4>Productos</h4>
                                            <a href="#">#1 estacines meteorologicas</a>
                                            <a href="#">#2</a>
                                            <a href="#">#3</a>
                                            <a href="#">#4</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>

                                        </div> */}
                                    </div>
                                    <div>
                                        <div>
                                            <Link onClick={() => setIsShownService(false)} name="eolic" to="/servicio-eolico">
                                                <span>
                                                    <GiWindTurbine/>
                                                </span>
                                                <br/>
                                                    { t('headerEolic') }
                                            </Link> 
                                        </div>
                                        {/* <div>
                                            <h4>Productos</h4>
                                            <a href="#">#1 estacines meteorologicas</a>
                                            <a href="#">#2</a>
                                            <a href="#">#3</a>
                                            <a href="#">#4</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>

                                        </div> */}
                                    </div>
                                    <div>
                                        <div>
                                            <Link onClick={() => setIsShownService(false)} name="solar" to="/servicio-solar">
                                                <span>
                                                    <FaSolarPanel/>
                                                </span>
                                                <br/>
                                                    { t('headerSolar') }
                                            </Link> 
                                        </div>
                                        {/* <div>
                                            <h4>Productos</h4>
                                            <a href="#">#1 estacines meteorologicas</a>
                                            <a href="#">#2</a>
                                            <a href="#">#3</a>
                                            <a href="#">#4</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>

                                        </div> */}
                                    </div>
                                    {/* <div>
                                        <div>
                                            <Link onClick={() => setIsShownService(false)} name="company" to="/servicio-industrial">
                                                <span>
                                                    <FaIndustry/>
                                                </span>
                                                <br/>
                                                    Industrial
                                            </Link> 
                                        </div>
                                        <div>
                                            <h4>Productos</h4>
                                            <a href="#">#1 estacines meteorologicas</a>
                                            <a href="#">#2</a>
                                            <a href="#">#3</a>
                                            <a href="#">#4</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>
                                            <a href="#">#5</a>

                                        </div>
                                    </div> */}
                                </div>
                            )}
                        <button onClick={() => setIsShownService(true)}>{t('services')}</button> 
                        </li>
                        <li className="option-header">
                            <Link onClick={() => setIsShownService(false)}
                                name="sendMail" to="/contacto">
                                {t('contact')}
                            </Link> 
                        </li>
                        <li className="option-header">
                            <Link onClick={() => setIsShownService(false)}
                                name="ubication" to="/ubicacion">
                                {t('ubication')}
                            </Link> 
                        </li>
                    </ul>
                    :
                    <div className="container-hamburger-menu">
                        <a href='#' className="hamburger-menu" onClick={() => handleMenu(true)}>
                            <span><IoMdMenu /></span>
                        </a>
                        
                        <div className="content-menu disabled">
                            <div>
                                <div>
                                    <a href='#' onClick={() => handleMenu(false)}><AiFillCloseCircle /></a>
                                </div>
                                <h3>WindAndSun</h3>
                                <Link onClick={() => handleMenu(false)} to="/">Home</Link>
                                <h3>{t('service')}</h3>
                                <Link onClick={() => handleMenu(false)} to="/servicio-ambiental" >
                                    {t('headerEnvironment')}
                                </Link>
                                <Link onClick={() => handleMenu(false)} to="/servicio-eolico" >
                                    {t('headerEolic')}
                                </Link>
                                <Link onClick={() => handleMenu(false)} to="/servicio-solar">
                                    {t('headerSolar')}
                                    
                                </Link>
                                <h3>
                                    {t('information')}
                                </h3>
                                <Link onClick={() => handleMenu(false)} to="/contacto">
                                    {t('contact')}
                                </Link>
                                <Link onClick={() => handleMenu(false)} to="/ubicacion">
                                    {t('ubication')}
                                </Link>
                                <a href='#' onClick={() => handleMenu(false)}>
                                    {t('close')}
                                </a>
                            </div>
                        </div>

                        
                        
                    </div>
                }
            </div>
            <div style={{marginRight:'20px'}}>
                <FlagSelect onChange={handleChangeLanguage}/>
            </div>
        </div>

    </header>
    );
}

export default Header;