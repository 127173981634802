import React,{Component} from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../assets/styles/components/Loader.scss";
import Loader from 'react-loader-spinner'

export default class App extends Component {
//other logic
  render() {
    return (
      <>
        <div className='content-loader'>
          <div style={{ margin: '0 auto' }}>
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={100}
              width={100}
            //timeout={3000} //3 secs
            />
          </div>
        </div>
      </>
    );
  }
}