import React from 'react'
import '../assets/styles/components/GeneralCard.scss'

const GeneralCard = ({ title,imag,description }) => {
    return (
        <div className="card-container">
            <figure>
                <img src={ imag } alt="ImageEnvironment"/>
            </figure>
            <h4>
                { title }
            </h4>
            <p className="p-sm center-text">
                { description }
            </p>
        </div>
    )
}
export default GeneralCard;
