import React from "react"; // useState maneja estado, useEffect maneja transmision o peticiones api o eventos entre componente o escuchar cambios
import Hero from "../components/Hero";
import Company from "../components/Company";
import Allies from "../components/Allies";
import Divisor from "../components/Divisor";
import Services from "../components/Services";
import Contact from "../components/Contact";
import "../assets/styles/App.scss";
import images from '../Images';

const Home = () => {
    const { heroEnvironment,heroSolar,heroEolic } = images;
    return (
        <>
            <Hero hero={[heroEnvironment,heroEolic,heroSolar,heroEnvironment]} home={true}/>
            <Divisor />
            <Company />
            <Divisor />
            <Services />
            <Divisor />
            <Allies />
            <Divisor />
            <Contact />
            { /*<Divisor />
            <TeamWork /> */}
        </>
    )
}

export default Home;