import React, {Component} from 'react';
import ReactCustomFlagSelect from 'react-custom-flag-select';
import us from '../assets/flags/us.svg';
import es from '../assets/flags/es.svg';
import "../assets/styles/components/FlagSelect.scss";

class FlagSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        areaCode: 'es', phone: '', validate: '',
      },
    };
    console.log(props);
  }

  find(arr,obj) {
    const res = [];
    arr.forEach(o => {
      let match = false;
      Object.keys(obj).forEach(i => {
        if (obj[i] === o[i]) {
          match = true;
        }
      });
      if (match) {
        res.push(o);
      }
    });
    return res;
  };

  render() { 
    const FLAG_SELECTOR_OPTION_LIST = [
      { id: 'en', name: 'US', displayText: 'Inglish', locale: 'en-US', flag: us },
      { id: 'es', name: 'ES', displayText: 'Español', locale: 'es-EU', flag: es }
    ];
    const { areaCode, } = this.state.data;
    const currentItem = this.find(FLAG_SELECTOR_OPTION_LIST, { id: areaCode })[0];
    console.log(currentItem);

  return (
    <>
      <ReactCustomFlagSelect
        tabIndex={'1'} //Optional.[String or Number].Default: none.
        id={'phone'} //Optional.[String].Default: "". Input ID.
        name={'phone'} //Optional.[String].Default: "". Input name.
        value={currentItem.id} //Optional.[String].Default: "".
        disabled={false} //Optional.[Bool].Default: false.
        showArrow={true} //Optional.[Bool].Default: true.
        animate={true} //Optional.[Bool].Default: false.
        optionList={FLAG_SELECTOR_OPTION_LIST} //Required.[Array of Object(s)].Default: [].
        // selectOptionListItemHtml={<div>us</div>} //Optional.[Html].Default: none. The custom select options item html that will display in dropdown list. Use it if you think the default html is ugly.
        // selectHtml={<div>us</div>} //Optional.[Html].Default: none. The custom html that will display when user choose. Use it if you think the default html is ugly.
        classNameWrapper="" //Optional.[String].Default: "".
        classNameContainer="" //Optional.[String].Default: "".
        classNameOptionListContainer="" //Optional.[String].Default: "".
        classNameOptionListItem="" //Optional.[String].Default: "".
        classNameDropdownIconOptionListItem={''} //Optional.[String].Default: "".
        customStyleWrapper={{}} //Optional.[Object].Default: {}.
        customStyleContainer={{ border: 'none', fontSize: '12px' }} //Optional.[Object].Default: {}.
        customStyleSelect={{ width: '100px' }} //Optional.[Object].Default: {}.
        customStyleOptionListItem={{}} //Optional.[Object].Default: {}.
        customStyleOptionListContainer={{ maxHeight: '100px', overflow: 'auto', width: '100px', marginTop: '5px' }} //Optional.[Object].Default: {}.
        onChange={value => {
          this.setState({
            data: {
              areaCode: value
            }
          });
          this.props.onChange(value);
        }}
      // onBlur={() => {}} //Optional.[Func].Default: none.
      // onFocus={(e) => {console.log(e)}} //Optional.[Func].Default: none.
      // onClick={(e) => {console.log(e)}} //Optional.[Func].Default: none.
      />
    </>
  );
  }
}

export default FlagSelect;