import React from 'react';
import TitleSection from './TitleSection';
import Paragraph from "./Paragraph";
import ItalicParagraph from "./ItalicParagraph";
import dataIndustry from '../assets/static/home/dataIndustry.PNG';
import academic from '../assets/static/home/academic2.PNG';
import CardCircle from './CardCircle';
import CenterCardContainer from './CenterCardContainer';
import { useTranslation } from 'react-i18next';


const Company = () => {

    const { t } = useTranslation();

    return (
        <div id="company-section">
            <TitleSection title={t('AboutUs')} />
            <div>
                <Paragraph content={t("AboutUsDescription1")} />
            </div>
            <Paragraph content={t("AboutUsDescription2")} />
         
            <ItalicParagraph content={t("AboutUsDescription3")} />

            <CenterCardContainer>
                <CardCircle key="1" title={t("AboutUsCardIndustry")} picture={dataIndustry} />
                <CardCircle key="2" title={t("AboutUsCardAcademic")} picture={academic} />
            </CenterCardContainer>
        </div>
    )
}

export default Company