import React from "react";
import { Link } from 'react-router-dom';


const ServiceCard = ({title,ico,route}) => {
    return (
            <>
                <Link to={route} className="service-card">
                    <span>{ico}</span>
                    {title}
                </Link>
            </>
    )
}

export default ServiceCard;