import React from "react";
import { Link  } from "react-router-dom";
import '../assets/styles/components/Breadcrumbs.scss';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from "../routes/routes";
import { useTranslation } from 'react-i18next';

const Breadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs(routes);
    const { t } = useTranslation();
    console.log(breadcrumbs);

    return (
      <>
        {breadcrumbs.map(({
          match,
          breadcrumb,
          location
        }) => (
          <span className={`breadcrumb-wrap ${match.url === location.pathname ? "breadcrumb-wrap-active" : ""}`} key={match.url}>
            {match.url !== '/' && <span>/</span>}
            <Link to={match.url}><span> {t(`${breadcrumb.props.children}`)} </span></Link>
          </span>
        ))}
      </>
    );
  }

  export default Breadcrumbs;