import React from 'react';

const CenterContent = ({ children }) => {

    return (
        <div className="center-section center-section-component">
            { children }
        </div>
    )
}

export default CenterContent;