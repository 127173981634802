import React from 'react'
import '../assets/styles/components/CardImage.scss'

const CardImage = ({ title,picture,description,estilo }) => {
    
    const style = estilo;
    return (
        <div className="card-container-eolic" style={style}>
            <figure>
                <img src={ picture } alt="cardImage"/>
            </figure>
            <div>
                <h4>
                    { title }
                </h4>
                {
                    <p>{description}</p>
                }
            </div>
        </div>
    )
}
export default CardImage;
