import React from 'react';
import '../assets/styles/components/CardCircle.scss';

function CardCircle({ title,picture }) {
    return (
        <div className="card-circle-body">
            <figure>
                <img src={ picture } alt="card-picture"/>
            </figure>
            <div>
                <h3>
                    { title }
                </h3>
            </div>
        </div>
    )
}

export default CardCircle;