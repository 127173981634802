import React from 'react';
import '../assets/styles/components/Footer.scss';
import RedSocial from '../components/RedSocial';
import { Link } from 'react-router-dom';
import images from '../Images';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { mainLogo } = images;
  const { t } = useTranslation();
  	return (
    	<div>
    		<RedSocial />
			<footer className="footer">
				<div className="footer-container">
					<img src={mainLogo} alt="logo" />
					<div>
					<Link to="/servicio-ambiental">{t('headerEnvironment')}</Link>
					<Link to="/servicio-eolico">{t('headerEolic')}</Link>
					<Link to="/servicio-solar">{t('headerSolar')}</Link>
					<Link to="/contacto">{t('contact')}</Link>
					<Link to="/ubicacion">{t('ubication')}</Link>
					</div>
					<div>
					<p>{t('directionFooter')}</p>
					<p>{t('phoneFooter')} <strong>+52 55 3685 2029</strong></p>
					<p>{t('EmailFooter')} <strong>julio.alvarez@windandsun.net</strong></p>
					</div>
				</div>
			</footer>
    	</div>
	);
  }


export default Footer;