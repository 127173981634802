import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Copyright from "./Copyright";

const Layout = ({ children }) => (
    <>
        <Header />
            { children }
        <Footer />
        <Copyright />
    </>
);

export default Layout;