import React from "react";
import { useTranslation } from 'react-i18next';
import '../assets/styles/components/Compyright.scss';

const Copyright = () => {
    const { t } = useTranslation();
    return (
        <div className="copyright">
            { t('Copyright') }
        </div>
    )
}

export default Copyright;