import React from "react";
import Hero from "../components/Hero";
import Divisor from "../components/Divisor";
import ServiceMap from "../components/ServiceMap";
import "../assets/styles/App.scss";
import images from '../Images';
import Breadcrumbs from "../components/Breadcrumbs";
import { useTranslation } from 'react-i18next';

const Ubication = () => {
    const { heroMap } = images;
    const { t } = useTranslation();
    return (
        <>
            <Hero hero={ heroMap } >  
                <h1>{t('ubication')}</h1>
                <h3>{t('subTitleUbication')}</h3>
                <h3>{t('subTitleUbication2')}</h3>
            </Hero>
            <Breadcrumbs />
            <Divisor />
            <ServiceMap />
        </>
    )
}

export default Ubication;