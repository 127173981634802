import React from "react";
import Hero from "../components/Hero";
import Divisor from "../components/Divisor";
import SendMailForm from "../components/SendMail";
import "../assets/styles/App.scss"
import images from '../Images';
import Breadcrumbs from "../components/Breadcrumbs";
import { useTranslation } from 'react-i18next';

const SendMail = () => {     
    const { contactHero } = images;
    const { t } = useTranslation();

    return (
        <>
            <Hero hero={contactHero} >  
                <h1>{t('contact')}</h1>
                <h1>{t('byEmail')}</h1>
                <h3>{t('subTitleEmail')}</h3>
            </Hero>
            <Breadcrumbs />
            <Divisor />
            <SendMailForm />   
        </>
    )
}

export default SendMail;