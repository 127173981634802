import React from "react";
import ServiceCard from './ServiceCard';
import '../assets/styles/components/ServiceCard.scss';
import CenterCardContainer from './CenterCardContainer';
import { FaSolarPanel, FaIndustry } from 'react-icons/fa'
import { GiWindTurbine,GiLindenLeaf } from "react-icons/gi";
import TitleSection from './TitleSection';
import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    return (
        <div>
            <TitleSection title={t("ServicesWeProvide")} />
            <CenterCardContainer >
                <ServiceCard ico={<GiLindenLeaf/>} title={t("EnvironmentService")} route="/servicio-ambiental" />
                <ServiceCard ico={<GiWindTurbine/>} title={t("EolicService")} route="/servicio-eolico" />
                <ServiceCard ico={<FaSolarPanel/>} title={t("SolarService")} route="/servicio-solar" />
            </CenterCardContainer>
        </div>
    )
}

export default Services;