import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../components/Layout";
import ScrollToTop from "../components/ScrollToTop";
import routes from "../routes/routes";

const Dashboard = () => (
    <BrowserRouter>
        <ScrollToTop>
            <Layout onUpdate={() => console.log('modificando')}>
                <Switch>
                    {
                        routes.map((route, i) => (
                            <Route key={i} exact={route.exact} path={route.path} component={route.component} />
                        ))
                    }
                </Switch>
            </Layout>
        </ScrollToTop>
    </BrowserRouter>
);
export default Dashboard;