import React, { useState, useEffect } from "react";
import Hero from "../components/Hero";
import Divisor from "../components/Divisor";
import TitleSection from "../components/TitleSection";
import Paragraph from "../components/Paragraph";
import CenterCardContainer from '../components/CenterCardContainer';
import CardImage from '../components/CardImage';
import "../assets/styles/App.scss"
import images from '../Images';
import { solar } from '../cards.json';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from "../components/Breadcrumbs";

const SolarModule = () => {     
    const { heroSolar } = images;
    const { t } = useTranslation();
    const [infoSolar, setInfo] = useState([]);

    useEffect(() => {
        setInfo(solar);
    }, []);

    return (
        <>
            <Hero hero={heroSolar} button={false} >  
                <h1>{t('service')}</h1>
                <h1>{t('titleHeroSolar')}</h1>
                <h3>{t('subTitleHeroSolar')}</h3>
            </Hero>
            <Breadcrumbs />
            <Divisor />
            <TitleSection title={t('solarPlantTitle')} />
            <Paragraph content={t('solarPlantDescript')} />
            <Divisor />
            <TitleSection title={t('SolarProductCatalog')} />
            <CenterCardContainer>
                {
                    infoSolar?.map(({id,title,description,cover}) => (
                        <CardImage key={id} title={t(title)} picture={images[cover]} description={t(description)} />
                    ))
                }    
            </CenterCardContainer >                    
        </>
    )
}

export default SolarModule;