import React, { useState, useEffect } from "react";
import Hero from "../components/Hero";
import Divisor from "../components/Divisor";
import TitleSection from "../components/TitleSection";
import Paragraph from "../components/Paragraph";
import CardImage from '../components/CardImage';
import CenterCardContainer from '../components/CenterCardContainer';
import images from '../Images';
import { eolic } from '../cards.json';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from "../components/Breadcrumbs";

const Eolic = () => {
    const { t } = useTranslation();
    const { heroEolic } = images;
    const [card, setCard] = useState([]);

    useEffect(() => {
        setCard(eolic);
    }, []);

    return (
        <>
            <Hero hero={heroEolic} button={false} >  
                <h1>{t('service')}</h1>
                <h1>{t('eolicPlant')}</h1>
                <h3>{t('subTitleHeroEolic')}</h3>
            </Hero>
            <Breadcrumbs />
            <Divisor />
            <TitleSection title={t('eolicStudy')} />
            <Paragraph content={t('eolicdescript1')} />
            <Paragraph content={t('eolicdescript2')} />
            <Divisor />
            <TitleSection title={t("eolicProducts")} />
            <CenterCardContainer>
                {
                    card?.map(({id,title,description,cover}) => (
                        <CardImage key={id} title={t(title)} picture={images[cover]} description={t(description)} />
                    ))
                }    
            </CenterCardContainer >
        </>
    )
}

export default Eolic;