import Ubication from "../modules/Ubication";
import ServiceEnvironment from "../modules/Environment";
import ServiceEolic from "../modules/Eolic";
import ServiceSolar from "../modules/Solar";
import ServiceIndustry from "../modules/Industry";
import SendMail from "../modules/SendMail";
import NotFound from "../modules/NotFound";
import Home from "../modules/Home";

const routes = [
    {
        path: "/",
        component: Home,
        breadcrumb: 'home',
        exact: true,
        t: 'home'
    },
    {
        path: "/ubicacion",
        component: Ubication,
        breadcrumb: 'ubication',
        t: 'ubication'
    },
    {
        path: "/servicio-ambiental",
        component: ServiceEnvironment,
        breadcrumb: 'headerEnvironment',
        t: 'headerEnvironment'
    },
    {
        path: "/servicio-eolico",
        component: ServiceEolic,
        breadcrumb: 'headerEolic',
        t: 'headerEolic'
    },
    {
        path: "/servicio-solar",
        component: ServiceSolar,
        breadcrumb: 'headerSolar',
        t: 'headerSolar'
    },
    {
        path: "/contacto",
        component: SendMail,
        breadcrumb: 'contact',
        t: 'contact'
    },
    {
        path: "*",
        component: NotFound,
        breadcrumb: 'Ups!',
        t: 'home'
    }
  ];

  export default routes;