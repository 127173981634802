import React from 'react';

const CenterContent = ({ children }) => {
    
    return (
        <div className="center-section-card max-width-xs">
            { children }
        </div>
    )
}

export default CenterContent;