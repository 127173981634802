import React, { useState, useEffect } from "react";
import L from 'leaflet';
import { MapContainer , TileLayer, Marker } from 'react-leaflet';
import Popup from 'react-leaflet-editable-popup';
import 'leaflet/dist/leaflet.css';
import markericon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markericon from 'leaflet/dist/images/marker-icon.png';
import markershadow from 'leaflet/dist/images/marker-shadow.png';
import { useTranslation } from 'react-i18next';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markericon2x,
    iconUrl: markericon,
    shadowUrl: markershadow
});

const style = {
    'height': '590px',
    'maxWidth':'70%',
    'margin':' 50px auto',
    'zIndex': '1'
}

const styleSpan = {
    'font': '12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif'
}

const Map = () => {

  const companyLatLong = [19.427693, -99.173005];
  const [state] = useState(companyLatLong);
  const [direction, setDirection] = useState('');
  const { t } = useTranslation();

  const myDirection = t('direction');

  useEffect(() => {
    setDirection(myDirection);
  }, [])


      return (
        <MapContainer center={state} zoom={17} scrollWheelZoom={true} style={style}>
            <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
                <Marker position={state} >
                    <Popup   open>
                        <span style={styleSpan}> {direction} </span>   
                    </Popup>
                </Marker>
        </MapContainer >
        );
    
  }
  
  export default Map;